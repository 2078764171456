import { useTranslation } from 'react-i18next';

import {
  AnimationShell,
  Checkbox,
  Field,
  Form,
  Icon,
  Input,
  Link,
  Select,
  TextArea,
  Toggle,
  Typography,
} from '@/components';
import { SaleOption } from '@/features/opportunities/types';
import { nullableStringSetter } from '@/helpers';
import { useDevelopmentStatus } from '@/hooks';
import { cn } from '@/utils/styles';

export type CreateOpportunityFormFields = {
  // Opportunity Form Fields
  name: string;
  deadline: Date;
  minimum_percentage: number;
  maximum_percentage: number;
  options: SaleOption[];
  comments: string | null;
  projects: string[];
  about_you: string | null;

  // Project Form Fields
  country: string;
  city: string;
  solar: { enabled: boolean; developmentStatus: string | null };
  wind: { enabled: boolean; developmentStatus: string | null };
  bess: { enabled: boolean; developmentStatus: string | null };
};

export type CreateOpportunityFormProps = {
  readonly id: string;
  readonly onSubmit: (data: CreateOpportunityFormFields) => void;
};

export function CreateOpportunityForm({ id, onSubmit }: CreateOpportunityFormProps) {
  const { t } = useTranslation(['opportunities']);
  const { options: developmentStatusOptions } = useDevelopmentStatus();

  const options = {
    defaultValues: {
      name: '',
      deadline: new Date(),
      minimum_percentage: 0,
      maximum_percentage: 100,
      options: [],
      comments: null,
      projects: [],
      about_you: null,

      country: 'ES',
      city: '',
    },
  };

  return (
    <Form<CreateOpportunityFormFields> id={id} onSubmit={onSubmit} options={options}>
      {({ formState, register, watch }) => (
        <div className="space-y-6">
          <Typography as="p" color="primary" size="body-3" weight="normal" className="mt-1">
            {t(
              'You are starting to create a project in nTeaser. Please fill in this section with the general details of the project, and continue completing all required fields. '
            )}
          </Typography>
          <Typography as="p" color="primary" size="body-3" weight="semibold" className="mt-1">
            {t(
              'A member of the nTeaser team will contact you before the project is published to review the information.'
            )}
          </Typography>
          <hr />
          <div>
            <Typography as="h5" color="primary" size="title" weight="normal">
              {t('Sale process')}
            </Typography>
            <Typography as="p" size="body-3" color="tertiary" weight="normal" className="mt-1">
              {t('Please complete this section with the relevant details of the sale process for the project.')}
            </Typography>
          </div>
          <Field
            id="name"
            label={t('Opportunity title')}
            error={formState.errors['name']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="name"
              color={formState.errors['name'] ? 'error' : 'primary'}
              autoComplete="off"
              registration={register('name', {
                required: t('This field is required'),
                setValueAs: nullableStringSetter,
              })}
            />
          </Field>

          <Field id="deadline" label={t('Offers Deadline')} error={formState.errors['deadline']} delay={0.15}>
            <Input
              id="deadline"
              color={formState.errors['deadline'] ? 'error' : 'primary'}
              registration={register('deadline', { required: t('This field is required'), valueAsDate: true })}
              type="date"
              className="space-x-2"
            />
          </Field>

          <Field id="minimum_percentage" label={t('Percentage range')} delay={0.3}>
            <div className="flex w-full items-center">
              <div className=" ">
                <Input
                  id="minimum_percentage"
                  placeholder="0"
                  color={formState.errors['minimum_percentage'] ? 'error' : 'primary'}
                  registration={register('minimum_percentage', {
                    required: t('This field is required'),
                    min: 0,
                    max: 100,
                    valueAsNumber: true,
                  })}
                  type="number"
                  suffix="%"
                />
              </div>
              <span className="px-3">-</span>
              <div className="">
                <Input
                  id="maximum_percentage"
                  placeholder="100"
                  color={formState.errors['maximum_percentage'] ? 'error' : 'primary'}
                  registration={register('maximum_percentage', {
                    required: t('This field is required'),
                    min: 0,
                    max: 100,
                    valueAsNumber: true,
                  })}
                  type="number"
                  suffix="%"
                />
              </div>
            </div>
          </Field>

          <Field label={t('Sale Options')} delay={0.45} classes={{ field: 'space-y-2', input: 'flex space-x-3' }}>
            <Checkbox color="primary" registration={register('options')} value="as-is">
              {t('As is')}
            </Checkbox>
            <Checkbox color="primary" registration={register('options')} value="rtb">
              {t('Ready to Build')}
            </Checkbox>
            <Checkbox color="primary" registration={register('options')} value="co-development">
              {t('Co-development')}
            </Checkbox>
            <Checkbox color="primary" registration={register('options')} value="cod">
              {t('COD')}
            </Checkbox>
            <Checkbox color="primary" registration={register('options')} value="operation">
              {t('Operation')}
            </Checkbox>
          </Field>

          <Field id="comments" label={t('Comments')} error={formState.errors['comments']} delay={0.6}>
            <TextArea
              id="comments"
              color="primary"
              placeholder="Type here..."
              registration={register('comments', { setValueAs: nullableStringSetter })}
            />
          </Field>

          <hr />

          <div>
            <Typography as="h5" color="primary" size="title" weight="normal">
              {t('Projects')}
            </Typography>
            <Typography as="p" size="body-3" color="tertiary" weight="normal" className="mt-1">
              {t('Please, fill in the location and technologies included in the project.')}
              <b>
                {t('If you want to upload a portfolio of projects, please contact us at ')}{' '}
                <Link to="mailto:projects@nteaser.com">projects@nteaser.com</Link>.
              </b>
            </Typography>
          </div>

          <div className="flex space-x-3">
            <Field
              id="country"
              label={t('Country')}
              error={formState.errors['country']}
              delay={0.6}
              classes={{ field: 'w-1/2' }}
            >
              <Select
                id="country"
                color={formState.errors['country'] ? 'error' : 'primary'}
                registration={register('country', { required: t('This field is required') })}
                // TODO: use useCountries hook
                options={[{ label: t('Spain'), value: 'ES' }]}
                className="w-full"
              />
            </Field>
            <Field
              id="city"
              label={t('City')}
              error={formState.errors['city']}
              delay={0.75}
              classes={{ field: 'w-1/2' }}
            >
              <Input
                id="city"
                color={formState.errors['city'] ? 'error' : 'primary'}
                autoComplete="off"
                registration={register('city', { required: t('This field is required') })}
              />
            </Field>
          </div>

          <div>
            <Typography as="h5" color="primary" size="title" weight="normal">
              {t('Technology')}
            </Typography>
            <Typography as="p" size="body-3" color="tertiary" weight="normal" className="mt-1">
              {t(
                "Please complete this section by choosing the technology of your project, or technologies if it's a hybridization. Additionally, select the development status of each technology."
              )}
            </Typography>
          </div>

          <div className="divide-y">
            <div className="flex w-full items-center justify-between space-x-3 py-3">
              <div className="flex w-1/2 shrink-0 items-center justify-start gap-x-2">
                <Toggle registration={register('solar.enabled')} />

                <AnimationShell type="opacity" className={cn('inline-flex items-center gap-2.5')}>
                  <Icon name="solar" className="h-8 w-8 text-primary-700" />

                  <AnimationShell type="slide-left">
                    <Typography as="h3" size="body-2" color="primary" weight="semibold">
                      {t('Solar')}
                    </Typography>
                  </AnimationShell>
                </AnimationShell>
              </div>
              <div className="w-1/2 shrink-0">
                <Select
                  color="primary"
                  registration={register('solar.developmentStatus', { disabled: !watch('solar.enabled') })}
                  options={developmentStatusOptions}
                />
              </div>
            </div>

            <div className="flex w-full items-center justify-between space-x-3 py-3">
              <div className="flex w-1/2 shrink-0 items-center justify-start gap-x-2">
                <Toggle registration={register('wind.enabled')} />

                <AnimationShell type="opacity" className={cn('inline-flex items-center gap-2.5')}>
                  <Icon name="wind" className="h-8 w-8 text-primary-700" />

                  <AnimationShell type="slide-left">
                    <Typography as="h3" size="body-2" color="primary" weight="semibold">
                      {t('Wind')}
                    </Typography>
                  </AnimationShell>
                </AnimationShell>
              </div>
              <div className="w-1/2 shrink-0">
                <Select
                  color="primary"
                  registration={register('wind.developmentStatus', { disabled: !watch('wind.enabled') })}
                  options={developmentStatusOptions}
                />
              </div>
            </div>

            <div className="flex w-full items-center justify-between space-x-3 py-3">
              <div className="flex w-1/2 shrink-0 items-center justify-start gap-x-2">
                <Toggle registration={register('bess.enabled')} />

                <AnimationShell type="opacity" className={cn('inline-flex items-center gap-2.5')}>
                  <Icon name="bess" className="h-8 w-8 text-primary-700" />

                  <AnimationShell type="slide-left">
                    <Typography as="h3" size="body-2" color="primary" weight="semibold">
                      {t('BESS')}
                    </Typography>
                  </AnimationShell>
                </AnimationShell>
              </div>
              <div className="w-1/2 shrink-0">
                <Select
                  color="primary"
                  registration={register('bess.developmentStatus', { disabled: !watch('bess.enabled') })}
                  options={developmentStatusOptions}
                />
              </div>
            </div>
          </div>

          <hr />
          <div>
            <Typography as="h5" color="primary" size="title" weight="normal">
              {t('About the seller')}
            </Typography>
            <Typography as="p" size="body-3" color="tertiary" weight="normal" className="mt-1">
              {t(
                'Please complete this section with an anonymous summary of you relevant experience in the development, operation, and management of similar assets. Provide details on you expertise and track record in the renewable energy sector.'
              )}
            </Typography>
          </div>

          <Field id="about_you" label={t('')} delay={1}>
            <TextArea
              id="about_you"
              color="primary"
              placeholder="Type here..."
              registration={register('about_you', { setValueAs: nullableStringSetter })}
            />
          </Field>
        </div>
      )}
    </Form>
  );
}
