import { t } from 'i18next';

import { Container, Link, Typography } from '@/components';
import { useBreadcrumbs } from '@/features/header';
import { useRegisterGAPageView } from '@/hooks';

import StripePricingTable from './stripe';
import { useSuscriptions } from '@/features/company/hooks';
import { useGetCompanyQuery } from '@/features/company';
import { useGetCurrentUserQuery } from '@/features/users';
import { skipToken } from '@reduxjs/toolkit/query';

export function PlanSettingsPage() {
  useBreadcrumbs(() => [{ title: t(`Plans & Billing`) }]);
  useRegisterGAPageView('Plans & Billing');

  const { data: user } = useGetCurrentUserQuery();
  const { data: company } = useGetCompanyQuery(user ? { id: user.company.id } : skipToken);
  const { hasTrialActive, hasPremiumActive } = useSuscriptions();

  const trial = hasTrialActive(company?.suscriptions ?? []);
  const premium = hasPremiumActive(company?.suscriptions ?? []);

  return (
    <div className="w-full py-10">
      <Container className="mt-10 space-y-12">
        <div className="rounded-xl border border-neutral-50 bg-white p-8">
          <div className="rounded-lgp-8 mt-4 flex justify-between gap-2">
            <div className="w-1/3">
              <Typography as="h3" size="title" color="primary" weight="normal">
                {t('Current plan: ')}
              </Typography>
              <Typography as="h1" size="heading-1" color="primary" weight="semibold" className="mt-2">
                {premium ? t('Business') : trial ? t('Trial') : t('Free')}
              </Typography>
              {trial && (
                <Typography as="p" size="body-2" color="primary" weight="normal" className="italic">
                  {t('Trial period expires next ')}{' '}
                  {new Date(trial.end_at).toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </Typography>
              )}
            </div>
            <div className="w-2/3">
              <Typography as="h3" size="title" color="primary" weight="semibold">
                {premium || trial ? t('Benefits of Business Accounts:') : t('Benefits of Business Accounts: ')}
              </Typography>
              <ul className=" mt-4 list-disc space-y-2 pl-8">
                <li>
                  <Typography as="p" size="body-2" color="primary" weight="normal">
                    <b>{t('Full Access')}: </b>
                    <span>{t('Get full access to all projects and technologies.')}</span>
                  </Typography>
                </li>
                <li>
                  <Typography as="p" size="body-2" color="primary" weight="normal">
                    <b>{t('Deeper Insights')}: </b>
                    <span>{t('Gain deeper insights into project status and the sales process.')}</span>
                  </Typography>
                </li>
                <li>
                  <Typography as="p" size="body-2" color="primary" weight="normal">
                    <b>{t('Instant Offers')}: </b>
                    <span>{t('Send acquisition offers and expressions of interest instantly.')}</span>
                  </Typography>
                </li>
                <li>
                  <Typography as="p" size="body-2" color="primary" weight="normal">
                    <b>{t('Complete Feedback')}: </b>
                    <span>{t('Receive complete and personalized feedback on all your offers')}</span>
                  </Typography>
                </li>
                <li>
                  <Typography as="p" size="body-2" color="primary" weight="normal">
                    <b>{t('Cutting-Edge Technologies')}: </b>
                    <span>{t('Explore cutting-edge technologies such as biogas and hydrogen')}</span>
                  </Typography>
                </li>
                <li>
                  <Typography as="p" size="body-2" color="primary" weight="normal">
                    <b>{t('Comprehensive Support')}: </b>
                    <span>{t('Enjoy complete support from the nTeaser team')}</span>
                  </Typography>
                </li>
                <li>
                  <Typography as="p" size="body-2" color="primary" weight="normal">
                    <b>{t('Co-Development Opportunities')}: </b>
                    <span>{t('Have access to co-development opportunities for mutual growth')}</span>
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
      {premium ? (
        <Container className="mt-4 space-y-12">
          <div className="rounded-xl border border-neutral-50 bg-white p-8">
            <Typography as="h3" size="title" color="primary" weight="semibold">
              {t('Bussines Plan')}
            </Typography>
            <div className="mt-4 flex items-center justify-between rounded-lg bg-neutral-50 p-8">
              <div>
                <Typography as="h5" size="title" color="primary" weight="semibold">
                  {t('Manage plan')}
                </Typography>
                <Typography as="p" size="body-2" color="secondary" weight="normal">
                  {t('Update card details / Update billing info / Download invoices')}
                </Typography>
              </div>
              <Link
                to="https://billing.stripe.com/p/login/5kAaIz6y09Uq4Du4gg"
                target="_blank"
                className="focus:ring-primary-300 hove:text-decoration-none rounded-lg bg-primary-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-800 hover:no-underline focus:outline-none"
              >
                {t('Manage plan')}
              </Link>
            </div>
          </div>
        </Container>
      ) : (
        <Container className="mt-10 space-y-12">
          <div className="rounded-xl border border-neutral-50 bg-white p-8">
            <div className="space-y-4">
              <Typography as="h3" size="title" color="primary" weight="semibold">
                {t('Manage Plan')}
              </Typography>

              <StripePricingTable />
            </div>
          </div>
        </Container>
      )}
    </div>
  );
}
