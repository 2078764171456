import { useFilteredOpportunities } from '@/features/opportunities/hooks';
import { useRegisterGAPageView } from '@/hooks';

import { OpportunitiesEmptyState } from '../empty-state';
import { OpportunitiesItems } from '../items';

export function OpportunitiesContent() {
  const { opportunities } = useFilteredOpportunities();
  useRegisterGAPageView('Home');

  if (!opportunities) return null;

  if (opportunities.length === 0) {
    return <OpportunitiesEmptyState />;
  }

  return <OpportunitiesItems opportunities={opportunities} />;
}
