import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';
import { eurosMWp, percentage } from '@/helpers';
import { useSaleOptions } from '@/hooks';

import { OfferDetails } from '../../types';

export type PriceCollapsableProps = {
  readonly offer: OfferDetails;
  readonly initialExpandedState?: boolean;
};

export function PriceCollapsable({ offer, initialExpandedState = true }: PriceCollapsableProps) {
  const { t } = useTranslation(['offers']);

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const { map: mapSaleOption } = useSaleOptions();

  const header = (
    <CollapsableHeader
      title={t('Price')}
      description={t('This section outlines the indicative price offered by the prospective buyer for the project.')}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
    />
  );

  return (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <CollapsableBody
        data={[
          { label: t('Price'), value: eurosMWp(offer?.price) },
          { label: t('Price for'), value: mapSaleOption(offer?.option) },
          {
            label: t('Willing to acquire'),
            value: `${percentage(offer?.percentage.minimum)} to ${percentage(offer?.percentage.maximum)}`,
          },
        ]}
      />
    </Collapsable>
  );
}
