import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BuyerType } from '../types';

export function useBuyerType() {
  const { t } = useTranslation(['connection']);

  const options = useMemo(
    () => [
      { label: t(`Final Buyer`), value: 'final-buyer' as BuyerType },
      { label: t(`Intermediary`), value: 'intermediary' as BuyerType },
    ],
    [t]
  );

  const kv = useMemo(
    () => options.reduce((map, option) => ({ ...map, [option.value]: option.label }), {}) as Record<BuyerType, string>,
    [options]
  );

  const map = useCallback((value: BuyerType) => kv[value], [kv]);

  return { options, kv, map };
}
