import { useTranslation } from 'react-i18next';

import { Button } from '@/components';

export type MyOpportunitiesHeaderProps = {
  readonly onCreateOpportunity: () => void;
};

export function MyOpportunitiesHeader({ onCreateOpportunity }: MyOpportunitiesHeaderProps) {
  const { t } = useTranslation(['opportunities']);

  return (
    <div className="flex justify-end">
      <Button size="md" color="primary" icon="plus" onClick={() => onCreateOpportunity()}>
        {t('Create opportunity')}
      </Button>
    </div>
  );
}
