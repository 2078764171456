import { cn } from '@/utils/styles';

import { IconProps } from './types';

export function Price({ className }: IconProps) {
  return (
    <svg
      className={cn(className)}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.0002 9.80001C3.1002 9.80001 0.200195 8.90001 0.200195 7.00001C0.200195 6.60001 0.500195 6.20001 1.0002 6.20001C1.5002 6.20001 1.8002 6.60001 1.8002 7.00001C1.8002 7.30001 3.3002 8.20001 6.0002 8.20001C6.4002 8.20001 6.8002 8.50001 6.8002 9.00001C6.8002 9.50001 6.4002 9.80001 6.0002 9.80001Z"
        fill="#323232"
      />
      <path
        d="M6.00029 13.8C3.10029 13.8 0.300293 12.9 0.300293 11C0.300293 10.6 0.600293 10.2 1.10029 10.2C1.60029 10.2 1.90029 10.5 1.90029 11C1.90029 11.3 3.40029 12.2 6.10029 12.2C6.50029 12.2 6.90029 12.5 6.90029 13C6.90029 13.5 6.40029 13.8 6.00029 13.8Z"
        fill="#323232"
      />
      <path
        d="M11.0002 6.00001H10.2002V3.00001C10.2002 2.60001 10.5002 2.20001 10.9002 2.20001C11.3002 2.20001 11.6002 2.50001 11.6002 3.00001V6.00001H11.0002Z"
        fill="#323232"
      />
      <path
        d="M14.0002 16.8C11.1002 16.8 8.2002 15.9 8.2002 14C8.2002 13.6 8.5002 13.2 9.0002 13.2C9.5002 13.2 9.8002 13.5 9.8002 14C9.8002 14.3 11.3002 15.2 14.0002 15.2C16.7002 15.2 18.2002 14.3 18.2002 14C18.2002 13.6 18.5002 13.2 19.0002 13.2C19.5002 13.2 19.8002 13.5 19.8002 14C19.8002 15.8 16.9002 16.8 14.0002 16.8Z"
        fill="#323232"
      />
      <path
        d="M14.0002 12.8C11.1002 12.8 8.2002 11.9 8.2002 10C8.2002 9.60001 8.5002 9.20001 9.0002 9.20001C9.5002 9.20001 9.8002 9.50001 9.8002 10C9.8002 10.3 11.3002 11.2 14.0002 11.2C16.7002 11.2 18.2002 10.3 18.2002 10C18.2002 9.60001 18.5002 9.20001 19.0002 9.20001C19.5002 9.20001 19.8002 9.50001 19.8002 10C19.8002 11.8 16.9002 12.8 14.0002 12.8Z"
        fill="#323232"
      />
      <path
        d="M9.0002 10.8C8.6002 10.8 8.3002 10.5 8.2002 10.1C8.2002 9.79999 8.3002 9.29999 8.8002 8.79999C9.7002 7.89999 11.7002 7.29999 14.0002 7.29999C14.4002 7.29999 14.8002 7.59999 14.8002 8.09999C14.8002 8.59999 14.5002 8.89999 14.0002 8.89999C11.9002 8.89999 10.4002 9.39999 9.9002 9.89999C9.8002 9.99999 9.8002 10.1 9.8002 10.1C9.8002 10.4 9.4002 10.7 9.0002 10.8Z"
        fill="#323232"
      />
      <path
        d="M14.0002 20.8C11.1002 20.8 8.2002 19.9 8.2002 18V10C8.2002 9.60001 8.5002 9.20001 9.0002 9.20001C9.5002 9.20001 9.8002 9.50001 9.8002 10V18C9.8002 18.3 11.3002 19.2 14.0002 19.2C14.4002 19.2 14.8002 19.5 14.8002 20C14.8002 20.5 14.4002 20.8 14.0002 20.8Z"
        fill="#323232"
      />
      <path
        d="M19.0002 10.8C18.6002 10.8 18.2002 10.5 18.2002 10C18.2002 10 18.2002 9.90001 18.1002 9.80001C17.6002 9.30001 16.1002 8.80001 14.0002 8.80001C13.6002 8.80001 13.2002 8.50001 13.2002 8.00001C13.2002 7.50001 13.5002 7.20001 14.0002 7.20001C16.3002 7.20001 18.3002 7.80001 19.2002 8.70001C19.7002 9.20001 19.8002 9.70001 19.8002 10C19.7002 10.4 19.4002 10.8 19.0002 10.8Z"
        fill="#323232"
      />
      <path
        d="M14.0002 20.8C13.6002 20.8 13.2002 20.5 13.2002 20C13.2002 19.5 13.5002 19.2 14.0002 19.2C16.8002 19.2 18.2002 18.3 18.2002 18V10C18.2002 9.60001 18.5002 9.20001 19.0002 9.20001C19.5002 9.20001 19.8002 9.50001 19.8002 10V18C19.8002 19.8 16.9002 20.8 14.0002 20.8Z"
        fill="#323232"
      />
      <path
        d="M6.0002 17.8C3.1002 17.8 0.200195 16.9 0.200195 15C0.200195 14.6 0.500195 14.2 1.0002 14.2C1.5002 14.2 1.8002 14.5 1.8002 15C1.8002 15.3 3.3002 16.2 6.0002 16.2C6.4002 16.2 6.8002 16.5 6.8002 17C6.8002 17.5 6.4002 17.8 6.0002 17.8Z"
        fill="#323232"
      />
      <path
        d="M6.0002 5.79999C3.1002 5.79999 0.200195 4.79999 0.200195 2.99999C0.200195 -0.600012 11.7002 -0.600012 11.7002 2.99999C11.8002 4.79999 8.9002 5.79999 6.0002 5.79999ZM6.0002 1.79999C3.2002 1.79999 1.8002 2.69999 1.8002 2.99999C1.8002 3.29999 3.2002 4.19999 6.0002 4.19999C8.8002 4.19999 10.2002 3.29999 10.2002 2.99999C10.2002 2.69999 8.8002 1.79999 6.0002 1.79999Z"
        fill="#323232"
      />
      <path
        d="M1.0002 15.8C0.600195 15.8 0.200195 15.5 0.200195 15V3.00001C0.200195 2.60001 0.500195 2.20001 1.0002 2.20001C1.5002 2.20001 1.8002 2.60001 1.8002 3.00001V15C1.8002 15.4 1.4002 15.8 1.0002 15.8Z"
        fill="#323232"
      />
    </svg>
  );
}
