import { isEmpty } from '@/types';
import { currencyFormat, dateFormat, isDateNewerThan } from '@/utils';

import { JSONStorage } from '../utils/storage';

export const euros = currencyFormat('EUR', 'es-ES');
export const shortEuros = currencyFormat('EUR', 'es-ES', 'short', 1, 1);
export const shortDate = dateFormat('DD-MM-YYYY');
export const timeFormat = dateFormat('hh:mm');
export const displayDate = dateFormat('MMM DD, YYYY');
export const isRecentlySynced = isDateNewerThan({ hours: 6 });

export const watts = (value?: number | null) => (value ? `${value.toFixed(1)} W` : null);
export const meters = (value?: number | null) => (value ? `${value.toFixed(2)} m` : null);
export const hours = (value?: number | null) => (value ? `${value.toFixed(2)} h` : null);
export const mw = (value?: number | null) => (value ? `${value.toFixed(1)} MW` : null);
export const mwp = (value?: number | null) => (value ? `${value.toFixed(1)} MWp` : null);
export const mwn = (value?: number | null) => (value ? `${value.toFixed(1)} MWn` : null);
export const mwh = (value?: number | null) => (value ? `${value.toFixed(1)} MWh` : null);
export const mwhmwp = (value?: number | null) => (value ? `${value.toFixed(0)} h (MWh/MWp)` : null);
export const mva = (value?: number | null) => (value ? `${value.toFixed(1)} (MVa/MWh)` : null);
export const kv = (value?: number | null) => (value ? `${value.toFixed(1)} kV` : null);

export const eurosMW = (value?: number | null) => (value ? `${euros(value)}/MW` : null);
export const eurosMWp = (value?: number | null) => (value ? `${euros(value)}/MWp` : null);

export const years = (value?: number | null) => (value ? `${value.toFixed(0)} years` : null);
export const weeks = (value?: number | null) => (value ? `${value.toFixed(0)} weeks` : null);

export const km = (value?: number | null) => (value ? `${value.toFixed(2)} km` : null);
export const ha = (value?: number | null) => (value ? `${value.toFixed(1)} ha` : null);
export const eurosPerHa = (value?: number | null) => (value ? `${euros(value)}/ha` : null);

export const percentage = (value?: number | null) => (value ? `${value.toFixed(2)}%` : null);
export const dateToMonth = (date?: string | number | null) => (date ? new Date(date).toISOString().slice(0, 7) : null);

export const sessionStorage = new JSONStorage(window.sessionStorage);
export const localStorage = new JSONStorage(window.localStorage);

export const nullableStringSetter = (value: string | null) => (isEmpty(value) ? null : value);

// Parse and format string as big integer like 10000000 into 10.000.000
export const parseStringToInteger = (value: string | null) =>
  value ? parseInt(value.toString().replace(/\./g, '')) : null;
export const formatStringAsInteger = (value: string | null) =>
  value ? value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d)\.?)/g, '.') : '';

export const getYearFromDate = (date?: string | null) => (date ? new Date(date).getFullYear() : null);
export const getQuarterFromDate = (date?: string | null) =>
  date ? Math.ceil((new Date(date).getMonth() + 1) / 3).toFixed(0) : null;
export const displayQuarter = (value?: string | null) =>
  value ? `Q${getQuarterFromDate(value)} ${getYearFromDate(value)}` : null;
export const getDateFromQuarterAndYear = (quarter: string | null, year: number | null) =>
  quarter && year ? new Date(year, parseInt(quarter) * 3, 1).toISOString() : null;

export const fileSize = (value?: string) => (value ? `${(parseInt(value, 10) / 1024).toFixed(2)} MB` : '');
