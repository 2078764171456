import { ClassValue } from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { cn } from '@/utils/styles';

import { colors, sizes, lines as textareaLines } from './constants';
import { TextAreaColor, TextAreaLines, TextAreaSize } from './types';
import { Typography, TypographyColor } from '../typography';

export type TextAreaProps = {
  readonly id?: string;
  readonly size?: TextAreaSize;
  readonly color?: TextAreaColor;
  readonly lines?: TextAreaLines;
  readonly resize?: boolean;
  readonly maxLength?: {
    maxValue: number;
    currentValue: number;
    color: TypographyColor;
  };
  readonly placeholder?: string;
  readonly autoComplete?: string;
  readonly registration?: Partial<UseFormRegisterReturn>;
  readonly className?: ClassValue;
};

export function TextArea({
  id,
  size = 'md',
  color = 'primary',
  lines = 2,
  resize = true,
  maxLength,
  placeholder,
  autoComplete = 'off',
  registration,
  className,
}: TextAreaProps) {
  return (
    <>
      <textarea
        id={id}
        placeholder={placeholder}
        autoComplete={autoComplete}
        className={cn(
          textareaLines[lines],
          'min-h-[40px] w-full transition',
          resize ? 'max-h-[50vh]' : 'resize-none',
          'placeholder:text-base-secondary font-normal text-base-primary sm:text-sm sm:leading-6',
          sizes[size],
          'appearance-none rounded border ring-2 ring-inset ring-transparent',
          colors[color],
          'disabled:border-neutral-50 disabled:bg-neutral-25',
          'disabled:text-neutral-disabled disabled:placeholder:text-neutral-disabled',
          'disabled:cursor-not-allowed',
          className
        )}
        {...registration}
      />
      {maxLength && (
        <Typography as="p" color={maxLength.color} size="body-3" weight="normal">
          {`Lenght of ${maxLength?.currentValue}/${maxLength.maxValue} characters.  `}
          {color === 'error' && (
            <span className="translate-z-0 translate-x-0 text-sm text-error-500">
              {`Max length of ${maxLength.maxValue} characters`}
            </span>
          )}
        </Typography>
      )}
    </>
  );
}
