import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Field, Form, Input, Link, Radio, TextArea, Typography } from '@/components';
import { Route as RoutePath } from '@/constants';
import { DocumentUpload, FileSchema } from '@/features/files';
import { useUploadOffer } from '@/features/offers/hooks';
import { DueDiligenceType, OfferMilestone, OfferMilestoneType } from '@/features/offers/types';
import { Opportunity, SaleOption } from '@/features/opportunities/types';
import { useRemoveAttachment } from '@/features/projects';
import { formatStringAsInteger, nullableStringSetter, parseStringToInteger } from '@/helpers';
import { useMilestones, useSaleOptions } from '@/hooks';

export type CreateOfferFormFields = {
  // Surface
  price: number;
  option: SaleOption;
  minimum_percentage: number;
  maximum_percentage: number;

  // Payment milestones
  milestones: OfferMilestone[];

  // Due diligence procedure details
  due_diligence_type: DueDiligenceType;
  due_diligence_weeks: number;
  due_diligence_requirements: string;
  due_diligence_comments: string;

  // Buyer
  buyer_type: string;
  buyer_comments: string;

  // Others
  file: string | null;
  comments: string;

  // Terms and conditions
  organization_approval: boolean;
  terms_and_conditions_accepted: boolean;
};

export type MilestoneFieldValues = {
  type: OfferMilestoneType;
  percentage: number;
  comments: string | null;
};

export type MilestonesFieldValues = MilestoneFieldValues[];

export type CreateOfferFormProps = {
  readonly id: string;
  readonly opportunity: Opportunity;
  readonly onSubmit: (data: CreateOfferFormFields) => void;
};

export function CreateOfferForm({ id, opportunity, onSubmit }: CreateOfferFormProps) {
  const { t } = useTranslation(['offers']);
  const [file, setFile] = useState<FileSchema[]>([]);

  const { kv: statusOptions } = useSaleOptions();
  const { options: optionsSelector } = useMilestones();

  const uploadOffer = useUploadOffer();
  const { deleteAttachment } = useRemoveAttachment();

  const textareaMaxLenght = 4000;

  const options = {
    defaultValues: {
      // Surface
      price: undefined,
      option: undefined,
      minimum_percentage: opportunity.minimum_percentage,
      maximum_percentage: opportunity.maximum_percentage,

      // Payment milestones
      milestones: [] as MilestonesFieldValues,

      // Due diligence procedure details
      due_diligence_type: undefined,
      due_diligence_weeks: undefined,
      due_diligence_requirements: undefined,
      due_diligence_comments: undefined,

      // Buyer
      buyer_type: undefined,
      buyer_comments: undefined,

      // Others
      file: null,
      comments: undefined,

      // Terms and conditions
      organization_approval: false,
      terms_and_conditions_accepted: false,
    },
  };

  return (
    <Form<CreateOfferFormFields> id={id} onSubmit={onSubmit} options={options}>
      {({ formState, register, setValue, watch }) => (
        <div className="space-y-6">
          <div>
            <Typography as="h5" color="primary" size="title" weight="normal">
              {t('Price')}
            </Typography>
            <Typography as="p" size="body-3" color="tertiary" weight="normal" className="mt-1">
              {t('This section outlines the indicative price offered by the prospective buyer for the project.')}
            </Typography>
          </div>

          <Field
            id="price"
            label={t('Price')}
            error={formState.errors['price']}
            delay={0}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              id="price"
              type="text"
              mask={formatStringAsInteger}
              placeholder={t('e.g. 150.000 €/MWp')}
              suffix="€/MWp"
              color={formState.errors['price'] ? 'error' : 'primary'}
              registration={register('price', {
                required: t('This field is required'),
                setValueAs: parseStringToInteger,
              })}
            />
          </Field>

          <Field
            id="option"
            label={t('Price for')}
            error={formState.errors['option']}
            delay={0.15}
            classes={{ field: 'space-y-2', input: 'flex space-x-3' }}
          >
            {opportunity.options.map((option: SaleOption) => (
              <Radio
                color={formState.errors['option'] ? 'error' : 'primary'}
                key={option}
                value={option}
                registration={register('option', { required: t('This field is required') })}
              >
                {statusOptions[option]}
              </Radio>
            ))}
          </Field>

          <Field label={t('Willing to acquire')} delay={0.3}>
            <div className="flex w-full items-center">
              <div>
                <Input
                  id="minimum_percentage"
                  placeholder={opportunity.minimum_percentage.toString()}
                  color={formState.errors['minimum_percentage'] ? 'error' : 'primary'}
                  registration={register('minimum_percentage', {
                    required: t('This field is required'),
                    min: 0,
                    max: 100,
                    valueAsNumber: true,
                  })}
                  type="number"
                  suffix="%"
                />
              </div>
              <span className="px-3">-</span>
              <div>
                <Input
                  id="maximum_percentage"
                  placeholder={opportunity.maximum_percentage.toString()}
                  color={formState.errors['maximum_percentage'] ? 'error' : 'primary'}
                  registration={register('maximum_percentage', {
                    required: t('This field is required'),
                    min: 0,
                    max: 100,
                    valueAsNumber: true,
                  })}
                  type="number"
                  suffix="%"
                />
              </div>
            </div>
          </Field>

          <hr />
          <div>
            <Typography as="h5" color="primary" size="title" weight="normal">
              {t('Payment Milestones')}
            </Typography>
            <Typography as="p" size="body-3" color="tertiary" weight="normal" className="mt-1">
              {t(
                'This section details the indicative payment milestones proposed by the prospective buyer, including the timing and amounts of each installment.'
              )}
            </Typography>
          </div>

          <Field id="milestones" delay={0.9}>
            {optionsSelector.map((option, index) => (
              <div className="mt-2 flex gap-4" key={`milestone-${index}`}>
                <Input
                  readOnly
                  color="primary"
                  defaultValue={option.label}
                  className="mr-4 w-full bg-neutral-25"
                  // options={optionsSelector}
                  registration={register(`milestones.${index}.type`, {
                    value: option.value as OfferMilestoneType,
                  })}
                />
                <Input
                  type="number"
                  placeholder="0"
                  defaultValue="0"
                  suffix="%"
                  id="percentage"
                  registration={register(`milestones.${index}.percentage`)}
                />
              </div>
            ))}
            <TextArea
              id="milestones_others"
              color="primary"
              className="mt-2"
              placeholder="Type here details of others milestone..."
              registration={register('milestones.3.comments', { setValueAs: nullableStringSetter })}
            />
          </Field>

          <hr />
          <div>
            <Typography as="h5" color="primary" size="title" weight="normal">
              {t('Due diligence procedure details')}
            </Typography>
            <Typography as="p" size="body-3" color="tertiary" weight="normal" className="mt-1">
              {t(
                "This section provides information about the prospective buyer's due diligence requirements, including the scope and expected timeline."
              )}
            </Typography>
          </div>

          <Field
            id="due_diligence_type"
            error={formState.errors['due_diligence_type']}
            label={t('Due Diligence Type')}
            delay={0.45}
            classes={{ field: 'space-y-2', input: 'flex space-x-3' }}
          >
            <Radio
              color={formState.errors['due_diligence_type'] ? 'error' : 'primary'}
              registration={register('due_diligence_type', { required: t('This field is required') })}
              value="external"
            >
              {t('External')}
            </Radio>
            <Radio
              color={formState.errors['due_diligence_type'] ? 'error' : 'primary'}
              registration={register('due_diligence_type', { required: t('This field is required') })}
              value="internal"
            >
              {t('Internal')}
            </Radio>
          </Field>

          <Field
            id="due_diligence_weeks"
            label={t('Due Diligence required time')}
            error={formState.errors['due_diligence_weeks']}
            delay={0.6}
          >
            <Input
              id="due_diligence_weeks"
              type="number"
              suffix=" Weeks"
              step="1"
              placeholder={t('e.g. 5')}
              color={formState.errors['due_diligence_weeks'] ? 'error' : 'primary'}
              registration={register('due_diligence_weeks', { required: t('This field is required') })}
            />
          </Field>

          {/* <Field id="due_diligence_requirements" label={t('Due Diligence Requirements')} delay={0.75}>
            <Input
              id="due_diligence_requirements"
              type="text"
              color={formState.errors['due_diligence_requirements'] ? 'error' : 'primary'}
              registration={register('due_diligence_requirements', { required: t('This field is required') })}
            />
          </Field> */}

          <Field id="due_diligence_comments" label={t('Due Diligence other comments')} delay={0.9}>
            <TextArea
              id="due_diligence_comments"
              color={formState.errors['due_diligence_comments'] ? 'error' : 'primary'}
              registration={register('due_diligence_comments', {
                setValueAs: nullableStringSetter,
                maxLength: textareaMaxLenght,
              })}
              maxLength={{
                maxValue: textareaMaxLenght,
                currentValue: watch('due_diligence_comments', '').length,
                color: watch('due_diligence_comments', '').length < textareaMaxLenght ? 'secondary' : 'error',
              }}
            />
          </Field>

          <hr />
          <div>
            <Typography as="h5" color="primary" size="title" weight="normal">
              {t('Final Buyer')}
            </Typography>
            <Typography as="p" size="body-3" color="tertiary" weight="normal" className="mt-1">
              {t(
                'This section includes information about the prospective  buyer, specifying whether they are the final purchaser or acting as an intermediary.'
              )}
            </Typography>
          </div>

          <Field
            id="buyer_type"
            label={t('Buyer Type')}
            delay={0.9}
            error={formState.errors['buyer_type']}
            classes={{ field: 'space-y-2', input: 'flex space-x-3' }}
          >
            <Radio
              color={formState.errors['buyer_type'] ? 'error' : 'primary'}
              registration={register('buyer_type', { required: t('This field is required') })}
              value="final-buyer"
            >
              {t('Final Buyer')}
            </Radio>
            <Radio
              color={formState.errors['buyer_type'] ? 'error' : 'primary'}
              registration={register('buyer_type', { required: t('This field is required') })}
              value="intermediary"
            >
              {t('Third-party representative')}
            </Radio>
          </Field>

          <Field id="buyer_comments" label={t("Description of the final buyer's activity")} delay={1.05}>
            <TextArea
              id="comments"
              color="primary"
              registration={register('buyer_comments', { setValueAs: nullableStringSetter })}
            />
          </Field>

          <hr />
          <div>
            <Typography as="h5" color="primary" size="title" weight="normal">
              {t('Others')}
            </Typography>
            <Typography as="p" size="body-3" color="tertiary" weight="normal" className="mt-1">
              {t('This section includes any additional information and documents related to the project or the offer.')}
            </Typography>
          </div>

          <Field id="comments" label={t('Comments')} delay={1.2}>
            <TextArea
              id="comments"
              color="primary"
              registration={register('comments', { setValueAs: nullableStringSetter })}
            />
          </Field>

          <Field id="document" label={t('Document')} delay={1.35}>
            <DocumentUpload
              multiple={false}
              files={file}
              onSelectFiles={(newFiles) => {
                uploadOffer(newFiles[0], (data) => {
                  setValue('file', data.id);
                  setFile([data]);
                });
              }}
              onUnSelectFiles={(oldFile) => {
                deleteAttachment(oldFile.id, () => {
                  setValue('file', null);
                  setFile([]);
                });
              }}
            />
          </Field>

          <div className="rounded-md bg-gray-100 p-4">
            <Field
              id="organization_approval"
              error={formState.errors['organization_approval']}
              delay={0.9}
              classes={{ field: 'space-y-1' }}
            >
              <Checkbox
                id="organization_approval"
                color="primary"
                registration={register('organization_approval', {
                  required: t('This consent is required'),
                })}
              >
                <Typography as="p" color="secondary" size="body-3" weight="normal">
                  {t('I confirm I have received the neccessary approvals within my organisation to submit this offer.')}
                </Typography>
              </Checkbox>
            </Field>
            <Field
              id="terms_and_conditions_accepted"
              error={formState.errors['terms_and_conditions_accepted']}
              delay={1.05}
              classes={{ field: 'space-y-1' }}
            >
              <Checkbox
                id="terms_and_conditions_accepted"
                color="primary"
                registration={register('terms_and_conditions_accepted', {
                  required: t('You must accept the terms and conditions to submit this offer.'),
                })}
              >
                <Typography as="p" color="secondary" size="body-3" weight="normal">
                  {t('I accept nTeaser’s ')}
                  <Link to={RoutePath.TermsAndConditions} target="_blank">
                    {t('Terms and Conditions')}
                  </Link>
                  {t(' and accept to pay a success fee of 1% of this NBO at SPA signing.')}
                </Typography>
              </Checkbox>
            </Field>
          </div>
        </div>
      )}
    </Form>
  );
}
