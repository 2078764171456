import { useTranslation } from 'react-i18next';

import { AnimationShell, Button, Field, Form, Input } from '@/components';

export type PasswordFormFields = {
  password: string;
  confirmed_password: string;
};

export type PasswordFormProps = {
  readonly onSubmit: (data: PasswordFormFields) => void;
};

export function PasswordForm({ onSubmit }: PasswordFormProps) {
  const { t } = useTranslation(['reset-password']);

  return (
    <Form<PasswordFormFields> onSubmit={onSubmit}>
      {({ watch, register, formState }) => (
        <div className="flex flex-col space-y-7">
          <Field
            id="password"
            label={t('Password')}
            error={formState.errors['password']}
            delay={0.15}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              type="password"
              autoComplete="new-password"
              color={formState.errors['password'] ? 'error' : 'primary'}
              registration={register('password', { required: t('This field is required') })}
            />
          </Field>

          <Field
            id="confirmed_password"
            label={t('Repeat password')}
            error={formState.errors['confirmed_password']}
            delay={0.25}
            classes={{ field: 'space-y-1' }}
          >
            <Input
              type="password"
              autoComplete="new-password"
              color={formState.errors['confirmed_password'] ? 'error' : 'primary'}
              registration={register('confirmed_password', {
                required: t('This field is required'),
                validate: (value) => value === watch('password') || t('The passwords do not match'),
              })}
            />
          </Field>

          <AnimationShell type="opacity" delay={0.55}>
            <Button type="submit" size="lg" className="w-full">
              {t('Continue')}
            </Button>
          </AnimationShell>
        </div>
      )}
    </Form>
  );
}
