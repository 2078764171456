import { useEffect } from 'react';

import { projectItemListTransformer } from '@/features/projects/helpers';
import { useSendOpportunityStats } from '@/features/stats/hooks';
import { ViewStats } from '@/features/stats/types';
import { useRegisterGAPageView } from '@/hooks';
import { isUndefined } from '@/types';

import { useOpportunityDetails } from '../../hooks';

import { AboutTheSellerCollapsable } from './about-the-seller-collapsable';
import { OpportunityCardPremium } from './opportunity-offers-view/opportunity-card-premium';
import { OpportunityOffersList } from './opportunity-offers-view/opportunity-offers-list';
import { OpportunityQuestionsTab } from './opportunity-questions-tab';
import { OpportunityTabDetails } from './opportunity-tab-details';
import { OpportunityTabProjects } from './opportunity-tab-projects';
import { ProjectsCollapsable } from './projects-collapsable';
import { SaleProcessCollapsable } from './sale-process-collapsable';
import { useBelongsToMyCompany } from '@/features/company/hooks';

export type OpportunityViewProps = {
  readonly id: string;
  readonly tabActive?: number;
};

export function OpportunityView({ id, tabActive }: OpportunityViewProps) {
  const { normalizedOpportunity, opportunity } = useOpportunityDetails(id);
  const projects = opportunity?.projects.map((project) => project.project);
  const projectsName = opportunity?.projects.map((project) => project.name);
  const projectItemList = projects?.map(projectItemListTransformer);
  const tabsLength = (projects?.length ?? 0) + 2;

  const opportunityBelongsToMyCompany = useBelongsToMyCompany(normalizedOpportunity?.company);
  const belongsToMyCompanyAndPublished = opportunityBelongsToMyCompany && normalizedOpportunity?.isPublished;
  const isNotPaid = !normalizedOpportunity?.isPaid;

  const sendViewStats = useSendOpportunityStats({ type: ViewStats });
  useRegisterGAPageView(normalizedOpportunity ? `Opportunity: ${normalizedOpportunity.name}` : null);

  useEffect(() => {
    if (!normalizedOpportunity) return;

    if (!opportunityBelongsToMyCompany) {
      sendViewStats(normalizedOpportunity.id);
    }
  }, [normalizedOpportunity, opportunityBelongsToMyCompany, sendViewStats]);

  if (!normalizedOpportunity) return null;
  if (!projectItemList) return null;
  if (!projects) return null;

  const opportunityDetailsWithProjectList = (
    <>
      <ProjectsCollapsable projects={projectItemList} names={projectsName} />
      <SaleProcessCollapsable opportunity={normalizedOpportunity} />
      <AboutTheSellerCollapsable opportunity={normalizedOpportunity} />
    </>
  );

  const myOpportunityDetailsStats =
    tabActive === 0 ? (
      <>
        {isNotPaid && <OpportunityCardPremium />}
        <OpportunityOffersList opportunity={normalizedOpportunity} />
      </>
    ) : tabActive === 1 ? (
      <OpportunityQuestionsTab opportunity={normalizedOpportunity} />
    ) : (
      opportunityDetailsWithProjectList
    );

  return (
    <div className="space-y-4">
      {isUndefined(tabActive) && opportunityDetailsWithProjectList}

      {!isUndefined(tabActive) && belongsToMyCompanyAndPublished && myOpportunityDetailsStats}

      {!isUndefined(tabActive) &&
        !belongsToMyCompanyAndPublished &&
        (tabActive === 0 ? (
          <>
            {opportunityBelongsToMyCompany && isNotPaid && <OpportunityCardPremium />}
            <OpportunityTabDetails opportunity={normalizedOpportunity} />
          </>
        ) : tabActive === tabsLength - 1 ? (
          <OpportunityQuestionsTab opportunity={normalizedOpportunity} />
        ) : (
          <OpportunityTabProjects
            projects={projects}
            tabActive={tabActive}
            opportunityId={id}
            isPaid={normalizedOpportunity.isPaid}
          />
        ))}
    </div>
  );
}
