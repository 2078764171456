import { useTranslation } from 'react-i18next';

import { Container } from '@/components';
import { useBreadcrumbs } from '@/features/header';
import { ServicesContent } from '@/features/services';

export function Services() {
  const { t } = useTranslation(['services']);
  useBreadcrumbs(() => [{ title: t(`Services`) }]);

  return (
    <div className="flex h-full w-full flex-col">
      <Container size="xl">
        <div className="flex flex-col px-4 py-6">
          <ServicesContent />
        </div>
      </Container>
    </div>
  );
}
