import { useCallback, useMemo } from 'react';

import { useGetDevelopmentStatusQuery } from '@/features/app';
import { useCurrentUser } from '@/features/users/hooks';

export function useDevelopmentStatus() {
  const { data: developmentStatus } = useGetDevelopmentStatusQuery();
  const user = useCurrentUser();

  const options = useMemo(() => {
    if (!developmentStatus) return [];
    if (!user) return [];

    return developmentStatus.map((devStatus) => ({
      label: devStatus.labels.find((l) => l.language === user.language)?.label ?? devStatus.labels[0].label,
      value: devStatus.code,
    }));
  }, [developmentStatus, user]);

  const kv = useMemo(
    () => options.reduce((map, option) => ({ ...map, [option.value]: option.label }), {}) as Record<string, string>,
    [options]
  );

  const map = useCallback((value: string) => kv[value], [kv]);

  return { options, kv, map };
}
