import { AnimationShell, Container, Typography } from '@/components';
import { cn } from '@/utils/styles';

import { ServiceDetails } from '../../types';

export type ServiceHeaderProps = {
  readonly service: ServiceDetails;
};

export function ServiceHeader({ service }: ServiceHeaderProps) {
  return (
    <div className="bg-neutral-10">
      <Container className="space-y-6 pb-4 pt-24">
        <AnimationShell type="opacity" className={cn('shrink-0 space-y-3')}>
          <div className="flex items-end justify-between">
            <AnimationShell type="slide-left" className="flex flex-col space-y-2">
              <Typography as="p" size="body-2" color="primary" weight="normal">
                {service.category.name}
              </Typography>
              <Typography as="h1" size="heading-1" color="primary" weight="semibold">
                {service.name}
              </Typography>
            </AnimationShell>
          </div>
        </AnimationShell>
      </Container>
    </div>
  );
}
