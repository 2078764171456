import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';
import { percentage } from '@/helpers';
import { useMilestones } from '@/hooks';

import { OfferDetails, OfferMilestoneType } from '../../types';

export type PaymentMilestonesCollapsableProps = {
  readonly offer: OfferDetails;
  readonly initialExpandedState?: boolean;
};

export function PaymentMilestonesCollapsable({
  offer,
  initialExpandedState = true,
}: PaymentMilestonesCollapsableProps) {
  const { t } = useTranslation(['offers']);
  const { kv: milestones } = useMilestones();

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const data = offer?.milestones.map((milestone) => ({
    label: milestones[milestone.type],
    value: percentage(milestone.percentage),
  }));

  const otherMilestone = offer.milestones.find((milestone) => milestone.type === ('other' as OfferMilestoneType));

  if (otherMilestone) {
    data.push({
      label: t('Other Details'),
      value: otherMilestone.comments || null,
    });
  }

  const header = (
    <CollapsableHeader
      title={t('Payment Milestones')}
      description={t(
        'This section details the indicative payment milestones proposed by the prospective buyer, including the timing and amounts of each installment.'
      )}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
    />
  );

  return (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <CollapsableBody data={data} />
    </Collapsable>
  );
}
