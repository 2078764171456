import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CapacityFilter } from './capacity';
import { CountryFilter } from './countries';
import { DevelopmentStatusFilter } from './development-status';
import { MyOpportunitiesFilter } from './my-opportunities';
import { SearchFilter } from './search';
import { TechnologyFilter } from './technology';

export function OpportunitiesFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const onChange = useCallback(
    (field: string, rawValue: string | string[]) => {
      const value = Array.isArray(rawValue) ? rawValue.join(',') : rawValue.trim();

      if (value.length === 0) {
        setSearchParams((searchParams) => {
          searchParams.delete(field);
          return searchParams;
        });
      } else {
        setSearchParams((searchParams) => {
          searchParams.set(field, value);
          return searchParams;
        });
      }
    },
    [setSearchParams]
  );

  return (
    <div className="flex flex-col space-y-6">
      <SearchFilter defaultValue={searchParams.get('search') ?? ''} onChange={(value) => onChange('search', value)} />
      <TechnologyFilter
        defaultValue={searchParams.get('technology')?.split(',') ?? []}
        onChange={(value) => onChange('technology', value)}
      />
      <DevelopmentStatusFilter
        defaultValue={searchParams.get('development_status') ?? ''}
        onChange={(value) => onChange('development_status', value)}
      />
      <CountryFilter
        defaultValue={searchParams.get('country') ?? ''}
        onChange={(value) => onChange('country', value)}
      />
      <CapacityFilter
        defaultValue={searchParams.get('capacity')?.split(',') ?? []}
        onChange={(value) => onChange('capacity', value)}
      />

      <hr className="mx-auto h-px w-full bg-neutral-50" />

      <MyOpportunitiesFilter
        defaultValue={searchParams.get('company') ?? ''}
        onChange={(value) => onChange('company', value ? 'me' : '')}
      />
    </div>
  );
}
