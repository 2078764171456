import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapsable, CollapsableBody, CollapsableHeader } from '@/components';

import { useBuyerType } from '../../hooks';
import { OfferDetails } from '../../types';

export type BuyerTypeCollapsableProps = {
  readonly offer: OfferDetails;
  readonly initialExpandedState?: boolean;
};

export function BuyerTypeCollapsable({ offer, initialExpandedState = true }: BuyerTypeCollapsableProps) {
  const { t } = useTranslation(['offers']);
  const { kv: options } = useBuyerType();

  const [isExpanded, setIsExpanded] = useState(initialExpandedState);

  const header = (
    <CollapsableHeader
      title={t('Buyer')}
      description={t(
        'This section includes information about the prospective  buyer, specifying whether they are the final purchaser or acting as an intermediary.'
      )}
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded((isExpanded) => !isExpanded)}
    />
  );

  return (
    <Collapsable index={0} isExpanded={isExpanded} header={header}>
      <CollapsableBody
        data={[
          { label: t('Buyer type'), value: options[offer?.buyerType] },
          { label: t('Description of activity'), value: offer?.buyerComments },
        ]}
      />
    </Collapsable>
  );
}
