import { AnimatePresence } from 'framer-motion';

import { MenuItem, MenuItemProps } from './menu-item';

export type MenuItems = Pick<MenuItemProps, 'to' | 'label' | 'icon' | 'active'>[];

export type MenuProps = {
  readonly collapsed: boolean;
  readonly items: MenuItems;
};

export function Menu({ collapsed, items }: MenuProps) {
  return (
    <nav>
      <ul className="w-full space-y-4">
        <AnimatePresence>
          {items.map(({ to, label, icon, active }, index) => (
            <MenuItem key={to} to={to} label={label} icon={icon} active={active} collapsed={collapsed} index={index} />
          ))}
        </AnimatePresence>
      </ul>
    </nav>
  );
}
