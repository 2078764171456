export const ViewStats = 'view';
export const DownloadStats = 'download_teaser';

export type OpportunityStats = {
  key: string;
  count: number;
};

export type UserStats = {
  id: string;
  created_at: string;
  created_by: string;
  key: string;
  agent: string;
  ip: string;
  count: number;
};

export type OpportunityStatsDetails = {
  opportunity: string;
  views: number;
  downloads: number;
};

export type UserStatsDetails = {
  logins: number;
};
