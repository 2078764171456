import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Link, Logo, LogoIcon, Sidebar, Typography } from '@/components';
import { Menu, MenuItems } from '@/components/menu';
import { Route } from '@/constants';
import { useSidebar } from '@/features/sidebar';
import { useGetCurrentUserQuery } from '@/features/users';
import { cn } from '@/utils/styles';
import { useDisplayTrialBanner } from '@/features/company/hooks';
import { Banner } from '@/components/banner';

export function MainSidebar() {
  const { t } = useTranslation(['sidebar']);

  const { isOpen, toogle } = useSidebar();

  const path = useLocation().pathname;

  const navbarItems = useMemo(
    () =>
      [
        {
          to: Route.Home,
          icon: 'opportunities',
          label: t(`Opportunities`),
          active: path === Route.Home || (path.startsWith(Route.Opportunities) && path !== Route.Opportunities),
        },
        {
          to: Route.Offers,
          icon: 'my-offers',
          label: t(`My Offers`),
          active: path.startsWith(Route.Offers),
        },
        // TODO-PROJECTS : REMOVE LINKS TO PROJECTS
        // {
        //   to: Route.Projects,
        //   icon: 'my-projects',
        //   label: t(`My Projects`),
        //   active: path.startsWith(Route.Projects),
        // },
        {
          to: Route.Opportunities,
          icon: 'my-projects',
          label: t(`My Projects`),
          active: path === Route.Opportunities,
        },
        {
          to: Route.Services,
          icon: 'services',
          label: t(`Services`),
          active: path.startsWith(Route.Services),
        },
      ] as MenuItems,
    [path, t]
  );

  const { data: user } = useGetCurrentUserQuery();

  const { inviteMember, expiredTrial } = useDisplayTrialBanner();
  const inviteMemberMessage = inviteMember();
  const expriedTrialMessage = expiredTrial();

  return (
    <Sidebar collapsed={isOpen} onChange={() => toogle()}>
      <div className="space-y-8">
        <Link to={Route.Home}>
          <div className={cn(isOpen ? 'px-1 py-4' : 'p-4')}>
            {isOpen ? <LogoIcon color="white" className="h-5 w-auto" /> : <Logo color="white" className="h-5 w-auto" />}
          </div>
        </Link>

        <div className={cn('space-y-0.5', isOpen ? 'opacity-0' : 'px-4 pb-4 opacity-100')}>
          <Typography size="caption" color="secondary" className="capitalize">
            {t(`Company`)}
          </Typography>

          <Typography as="p" size="title" color="white">
            {user?.company?.name}
          </Typography>
        </div>

        <Menu collapsed={isOpen} items={navbarItems} />

        {inviteMemberMessage ? <Banner banner={inviteMemberMessage} /> : null}
        {expriedTrialMessage ? <Banner banner={expriedTrialMessage} /> : null}
      </div>
    </Sidebar>
  );
}
