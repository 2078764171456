import { useTranslation } from 'react-i18next';

import { AnimationShell, Avatar, Icon, Link, Typography } from '@/components';
import { ASSETS_BASE_URL } from '@/config';

import { ServiceDetails } from '../../types';

export type ServiceContactProps = {
  readonly service: ServiceDetails;
};

export function ServiceContact({ service }: ServiceContactProps) {
  const { t } = useTranslation(['services']);

  return (
    <section className="space-y-8">
      <AnimationShell type="slide-left" className="space-y-4">
        <Typography as="h4" size="heading-2" color="primary" weight="semibold">
          {t('Contacts')}
        </Typography>
        {service.website && (
          <div className="flex items-center">
            <Icon name="web" className="mr-2 w-10" />
            <Link to={service.website} target="_blank">
              {t('Website')}
            </Link>
          </div>
        )}
        {service.linkedin && (
          <div className="flex items-center">
            <Icon name="linkedin" className="mr-2 w-10" />

            <Link to={service.linkedin} target="_blank">
              {t('Linkedin')}
            </Link>
          </div>
        )}
        {service.primaryPhone && (
          <div className="flex items-center">
            <Icon name="price" className="mr-2 w-10" />
            <Typography as="p" size="body-2" color="secondary" weight="normal">
              {service.primaryPhone}
            </Typography>
          </div>
        )}
        {service.secondaryPhone && (
          <div className="flex items-center">
            <Icon name="price" className="mr-2 w-10" />
            <Typography as="p" size="body-2" color="secondary" weight="normal">
              {service.secondaryPhone}
            </Typography>
          </div>
        )}
      </AnimationShell>

      {service.contacts.map((contact, index) => (
        <AnimationShell type="slide-left" className="grid grid-cols-4 items-center" key={`contact-${index}`}>
          <Avatar
            firstName={contact.firstName}
            lastName={contact.lastName}
            fullName={contact.fullName}
            avatar={contact.avatar ? `${ASSETS_BASE_URL}/${contact.avatar}` : ''}
            size="lg"
          />
          <div className="col-span-3">
            <Typography as="p" size="body-2" color="primary" weight="normal">
              {contact.fullName}
            </Typography>

            <Typography as="p" size="body-3" weight="normal" color="secondary" className="">
              {contact.position}
              <span className="inline-flex items-baseline">
                {contact.email && (
                  <Link to={`mailto:${contact.email}`} className="inline-flex gap-x-1">
                    <Icon name="mail" className="h-4 w-6" />
                  </Link>
                )}
                {contact.linkedin && (
                  <Link to={contact.linkedin} target="_blank" className="inline-flex">
                    <Icon name="linkedin" className="h-4 w-4" />
                  </Link>
                )}
              </span>
            </Typography>
          </div>
        </AnimationShell>
      ))}
    </section>
  );
}
