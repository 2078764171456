import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { AnimationShell, Chip, Icons, Link, Typography } from '@/components';
import { ChipColor } from '@/components/chips/types';
import { Route } from '@/constants';
import { getDeadlineStyles } from '@/features/opportunities/helpers';
import { useLocation } from '@/features/projects/hooks';
import { displayDate, mwp, mw } from '@/helpers';
import { useDevelopmentStatus } from '@/hooks';
import { cn } from '@/utils/styles';

import { OpportunityItemList } from '../../../types';

export type OpportunitiesItemProps = {
  readonly opportunity: OpportunityItemList;
  readonly index?: number;
};

export function OpportunitiesItem({ opportunity, index = 0 }: Readonly<OpportunitiesItemProps>) {
  const { t } = useTranslation(['opportunities']);
  const { map: mapDevelopmentStatus } = useDevelopmentStatus();
  const enabled = opportunity.deadline.category !== 'overdue';
  const pathTo = enabled ? Route.Opportunity.replace(':opportunityId', opportunity.id) : '';
  const location = useLocation({ location: opportunity ? opportunity.location : [] });

  const state = opportunity.technologies.state.list.map(mapDevelopmentStatus).join(', ');

  const status =
    opportunity.deadline.category === 'overdue'
      ? {
          color: 'disabled' as ChipColor,
          name: t('closed'),
        }
      : opportunity.isPaid
        ? {
            color: 'info' as ChipColor,
            name: t('public'),
          }
        : opportunity.status;

  const suffix = opportunity.hasPV ? mwp : mw;

  return (
    <motion.div
      initial={{ y: 0 }}
      whileHover={{ y: -6 }}
      transition={{ type: 'spring', stiffness: 100 }}
      className="w-full"
    >
      <Link to={pathTo} className={cn('hover:no-underline', !enabled ? 'pointer-events-none' : '')}>
        <AnimationShell
          type="scale"
          delay={0.1 * index}
          className={cn(
            'h-full w-fit cursor-pointer rounded-md border border-neutral-50 p-4 opacity-100 shadow-sm transition hover:shadow-xl',
            !enabled ? 'bg-neutral-100' : 'bg-base-white'
          )}
        >
          <div className="group flex items-center justify-between space-x-1.5">
            <div className="w-20 shrink-0">
              <Icons icons={opportunity.technologies.icons.states} className="gap-1" />
            </div>

            <div className="w-80 shrink-0">
              <div className="flex items-center justify-start gap-1.5">
                <Typography as="span" size="body-2" color="primary" weight="semibold">
                  {opportunity.name}
                </Typography>
                {status.name ? <Chip color={status.color}>{status.name}</Chip> : null}
              </div>
            </div>

            <div className="w-24 shrink-0">
              <Typography as="p" size="body-3" color="primary" weight="normal">
                {suffix(opportunity.technologies.capacity)}
              </Typography>
            </div>

            <div className="w-40 shrink-0">
              <Typography as="p" size="body-3" color="primary" weight="normal">
                {state}
              </Typography>
            </div>

            <div className="w-48 shrink-0">
              <Typography as="p" size="body-3" color="primary" weight="normal">
                {location}
              </Typography>
            </div>

            <div className="w-52 shrink-0">
              <Typography
                as="p"
                size="body-3"
                color="primary"
                weight="normal"
                className={getDeadlineStyles(opportunity.deadline.category)}
              >
                Deadline: {displayDate(opportunity.deadline.date)}
              </Typography>
            </div>
          </div>
        </AnimationShell>
      </Link>
    </motion.div>
  );
}
